import { styled as MuiStyled } from '@mui/material/styles';

const defaultOptions = {
  shouldForwardProp: (prop: string) => !prop.startsWith('$'),
};

export const styled: typeof MuiStyled = (
  component: Parameters<typeof MuiStyled>[0],
  options: Parameters<typeof MuiStyled>[1],
) => MuiStyled(component, { ...options, ...defaultOptions });
