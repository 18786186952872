import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface ThemeStore {
  mode: 'dark' | 'light';
  changeMode: () => void;
}

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
}

enum ThemeStoreActions {
  CHANGE_MODE = 'changeMode',
}

export const useThemeStore = create<ThemeStore>()(
  devtools(
    persist(
      (set) => ({
        // mode: window.matchMedia('(prefers-color-scheme: dark)').matches
        //   ? ThemeMode.DARK
        //   : ThemeMode.LIGHT,
        mode: ThemeMode.DARK,
        changeMode: () =>
          set(
            (state) => ({
              mode: state.mode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK,
            }),
            false,
            ThemeStoreActions.CHANGE_MODE,
          ),
      }),
      { name: 'theme-storage' },
    ),
    { name: 'ThemeStore' },
  ),
);

export const selectAGGridTheme = (store: ThemeStore) =>
  store.mode === ThemeMode.DARK ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';

export const selectIsLightMode = ({ mode, changeMode }: ThemeStore) => ({
  isLightMode: mode === ThemeMode.LIGHT,
  changeMode,
});
