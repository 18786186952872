import { historyWorkspace } from './layout-configurations/history';
import { ledgerAccountingWorkspace } from '@wyden/layout-configurations/ledgerAccounting';
import { getTradingWorkspace } from './layout-configurations/trading';

export const PREDEFINED_WORKSPACES_IDS = {
  EMPTY: 'empty',
  FORMS: 'forms',
  CONFIG: 'config',
  TRADING: 'trading',
  HISTORY: 'history',
  LEDGER_ACCOUNTING: 'ledger-accounting',
  INSTRUMENTS: 'instruments',
  PORTFOLIO_DATA: 'portfolio-data',
  PERMISSIONS: 'permissions',
  VENUE_ACCOUNTS: 'accounts',
};

// TODO we will need to somehow get those types from BE in the future
export const PREDEFINED_GROUPS = {
  ADMIN: 'admin',
  TRADER: 'trader',
  RETAIL: 'retail',
  MANAGER: 'manager',
} as const;

export const PORTFOLIO_GROUP_TAG_NAME = 'portfolioGroup';

export const ERRORS = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  CLIENT_ERROR: 'CLIENT_ERROR',
} as const;

export const PREDEFINED_WORKSPACES = {
  TRADING: {
    id: PREDEFINED_WORKSPACES_IDS.TRADING,
    name: 'Trading',
    json: getTradingWorkspace(),
    isTrading: true,
  },
  HISTORY: {
    id: PREDEFINED_WORKSPACES_IDS.HISTORY,
    name: 'History',
    json: historyWorkspace,
  },
  LEDGER_ACCOUNTING: {
    id: PREDEFINED_WORKSPACES_IDS.LEDGER_ACCOUNTING,
    name: 'Ledger Accounting',
    json: ledgerAccountingWorkspace,
  },
};

export const BREAKPOINTS = {
  xs: 0,
  min: 300,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const BORDER_RADIUS = '8px';

export const ORDERBOOK_TICK_SIZE_OPTIONS = [0.001, 0.05, 0.1, 0.5];

export const INFINITE_SCROLL_PAGE_SIZE = 50;
