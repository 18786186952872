import React, { Suspense } from 'react';
import { MainAppSkeleton } from './features/MainAppSkeleton';

const App = React.lazy(() => import('./App'));

export function AppLoader() {
  return (
    <Suspense fallback={<MainAppSkeleton />}>
      <App />
    </Suspense>
  );
}
