export enum WIDGETS_NAMES {
  WATCHLIST = 'WATCHLIST',
  ORDERS = 'ORDERS',
  ORDER_BOOK = 'ORDER_BOOK',
  EMPTY_WORKSPACE = 'EMPTY_WORKSPACE',
  SIMPLE_ORDER_FORM = 'SIMPLE_ORDER_FORM',
  INSTRUMENTS = 'INSTRUMENTS',
  PORTFOLIO_DATA = 'PORTFOLIO_DATA',
  TRADING_VIEW = 'TRADING_VIEW',
  VENUE_ACCOUNTS = 'VENUE_ACCOUNTS',
  LEDGER = 'LEDGER',
  POSITIONS_QUERY = 'POSITIONS_QUERY',
  POSITIONS_REALTIME = 'POSITIONS_REALTIME',
  PERMISSIONS = 'PERMISSIONS',
  EMPTY = 'EMPTY',
  ORDERS_HISTORY = 'ORDERS_HISTORY',
  TRANSACTIONS_HISTORY = 'TRANSACTIONS_HISTORY',
  KPIS_PLACEHOLDER = 'KPIS_PLACEHOLDER',
  INSTRUMENT_SEARCH = 'INSTRUMENT_SEARCH',
  PORTFOLIO_SELECT = 'PORTFOLIO_SELECT',
  TRADING_KIT = 'TRADING_KIT',
  TRANSACTIONS = 'TRANSACTIONS',
  WALLET_ACCOUNT_DATA = 'WALLET_ACCOUNT_DATA',
}

export const DELAYED_RENDERING_WIDGETS = [
  WIDGETS_NAMES.ORDERS,
  WIDGETS_NAMES.LEDGER,
  WIDGETS_NAMES.POSITIONS_QUERY,
  WIDGETS_NAMES.POSITIONS_REALTIME,
  WIDGETS_NAMES.ORDERS_HISTORY,
  WIDGETS_NAMES.TRANSACTIONS_HISTORY,
  WIDGETS_NAMES.TRADING_KIT,
  WIDGETS_NAMES.WATCHLIST,
  WIDGETS_NAMES.TRANSACTIONS,
];
