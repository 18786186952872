import { WIDGETS_NAMES } from '@wyden/features/widgets-renderer/widget-names';
import { v4 as uuidv4 } from 'uuid';

export const getTradingWorkspace = (watchListId?: string) => ({
  id: 'nest-basicRow',
  type: 'LayoutCell',
  name: 'MAIN',
  direction: 'DIRECTION_VERTICAL',
  children: [
    {
      id: 'nest-middle-row',
      type: 'LayoutCell',
      name: 'MIDDLEROW',
      direction: 'DIRECTION_HORIZONTAL',
      minSize: {
        height: 500,
      },
      preferredSize: {
        height: 500,
      },
      children: [
        {
          id: `TRADING_KIT-${uuidv4()}}`,
          displayName: '',
          type: 'LayoutComponent',
          name: WIDGETS_NAMES.TRADING_KIT,
          acceptFlavours: [],
          flavours: [],
          preferredSize: {
            height: 320,
          },
        },
        {
          id: watchListId || `WATCHLIST-${uuidv4()}}`,
          type: 'LayoutComponent',
          name: 'WATCHLIST',
          displayName: 'Watchlist',
          preferredSize: {
            width: 400,
          },
          maxSize: {
            width: 600,
          },
        },
      ],
    },
    {
      id: 'nest-bottom-row',
      type: 'LayoutCell',
      name: 'BOTTOMROW',
      direction: 'DIRECTION_HORIZONTAL',
      children: [
        {
          id: 'direction-stack-left',
          type: 'LayoutCell',
          name: 'SouthEastStack',
          direction: 'DIRECTION_STACK',
          children: [
            {
              id: `ORDERS-${uuidv4()}}`,
              type: 'LayoutComponent',
              name: WIDGETS_NAMES.ORDERS,
              displayName: 'Active Orders',
              direction: 'DIRECTION_HORIZONTAL',
              active: true,
            },
            {
              id: `ORDERS_HISTORY-${uuidv4()}}`,
              type: 'LayoutComponent',
              name: WIDGETS_NAMES.ORDERS_HISTORY,
              displayName: 'Orders History',
              direction: 'DIRECTION_HORIZONTAL',
            },
          ],
        },
        {
          id: 'direction-stack-right',
          type: 'LayoutCell',
          name: 'SouthWestStack',
          direction: 'DIRECTION_STACK',
          children: [
            {
              id: `POSITIONS-${uuidv4()}}`,
              type: 'LayoutComponent',
              name: WIDGETS_NAMES.POSITIONS_REALTIME,
              displayName: 'Positions (live)',
              direction: 'DIRECTION_HORIZONTAL',
              active: true,
            },
            {
              id: `TRANSACTIONS-${uuidv4()}}`,
              type: 'LayoutComponent',
              name: WIDGETS_NAMES.TRANSACTIONS,
              displayName: 'Recent Transactions',
              direction: 'DIRECTION_HORIZONTAL',
            },
          ],
        },
      ],
    },
  ],
});
