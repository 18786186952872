import { styled } from '@ui/styled';
import { Skeleton } from '@ui/Skeleton';
import { color } from '@ui/theme/colors';
import { getColorBasedOnTheme } from '@wyden/utils/styles';
import { ReactComponent as Logo } from '@wyden/assets/wyden-rgb.svg';

export function BodySkeleton() {
  return (
    <Container>
      <Skeletons>
        <Skeleton height={'100%'} width={'40%'} variant="rectangular" />
        <RightSkeletons>
          <Skeleton height={'100%'} variant="rectangular" />
          <Skeleton height={'100%'} variant="rectangular" />
        </RightSkeletons>
      </Skeletons>
    </Container>
  );
}

export function MainAppSkeleton() {
  return (
    <Container>
      <TopPart>
        <HeaderLogo />
        <StyledAvatarSkeleton variant="circular" height={40} width={40} />
      </TopPart>
      <Skeletons>
        <Skeleton height={'100%'} width={'40%'} variant="rectangular" />
        <RightSkeletons>
          <Skeleton height={'100%'} variant="rectangular" />
          <Skeleton height={'100%'} variant="rectangular" />
        </RightSkeletons>
      </Skeletons>
    </Container>
  );
}

const HeaderLogo = styled(Logo)`
  height: 56px;
  margin: 0 86px;
  color: ${getColorBasedOnTheme(
    color['light'].textElementsTextPrimary,
    color['dark'].textElementsTextPrimary,
  )};
`;

const Container = styled('div')`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const TopPart = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => color[theme.palette.mode].fillsSurfaceSurfaceSecondary};
`;

const StyledAvatarSkeleton = styled(Skeleton)`
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

const RightSkeletons = styled('div')`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const Skeletons = styled('div')`
  display: flex;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: 0 ${({ theme }) => theme.spacing(2)};
`;
