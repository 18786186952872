import { Theme } from '@mui/material/styles';
import { ThemeMode } from '@ui/useThemeStore';

export const getSpacing =
  (size: number) =>
  ({ theme }: { theme: Theme }) => {
    return theme.spacing(size);
  };

export const isDarkMode = (theme: Theme) => theme?.palette?.mode === ThemeMode.DARK;

export const getColorBasedOnTheme =
  (lightColor: string, darkColor: string) =>
  ({ theme }: { theme: Theme }): string =>
    !lightColor || !darkColor
      ? (theme.palette.background as unknown as string)
      : isDarkMode(theme)
      ? darkColor
      : lightColor;
