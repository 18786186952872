import { WIDGETS_NAMES } from '@wyden/features/widgets-renderer/widget-names';

export const ledgerAccountingWorkspace = {
  id: 'nest-basicRow',
  type: 'LayoutCell',
  direction: 'DIRECTION_VERTICAL',
  children: [
    {
      id: 'nest-mainRow',
      type: 'LayoutCell',
      direction: 'DIRECTION_VERTICAL',
      children: [
        {
          id: 'nest-main-workspace',
          type: 'LayoutCell',
          direction: 'DIRECTION_HORIZONTAL',
          children: [
            {
              id: 'nest-center-workspace',
              type: 'LayoutCell',
              name: 'CENTERWORKSPACE',
              direction: 'DIRECTION_HORIZONTAL',
              children: [
                {
                  id: 'nest-dashboard-workspace',
                  type: 'LayoutCell',
                  name: 'DASHBOARD',
                  children: [
                    {
                      id: 'nest-dashboard',
                      type: 'LayoutCell',
                      name: 'DASHBOARD_WORKSPACE',
                      direction: 'DIRECTION_VERTICAL',
                      children: [
                        {
                          id: 'nest-dashboard-workspace',
                          type: 'LayoutCell',
                          name: 'BOTTOMROW',
                          children: [
                            {
                              id: 'nest-workspace-content',
                              type: 'LayoutCell',
                              name: 'COLUMN',
                              direction: 'DIRECTION_HORIZONTAL',
                              children: [
                                {
                                  id: 'nest-south-west-column',
                                  type: 'LayoutCell',
                                  name: 'LEFTCOLUMN',
                                  children: [
                                    {
                                      id: 'accounting-positions',
                                      type: 'LayoutComponent',
                                      name: WIDGETS_NAMES.POSITIONS_QUERY,
                                      displayName: 'Positions',
                                    },
                                  ],
                                },
                                {
                                  id: 'nest-east-column',
                                  type: 'LayoutCell',
                                  name: 'RIGHTCOLUMN',
                                  direction: 'DIRECTION_VERTICAL',
                                  children: [
                                    {
                                      id: 'accounting-ledger',
                                      type: 'LayoutComponent',
                                      name: WIDGETS_NAMES.LEDGER,
                                      displayName: 'Ledger',
                                      direction: 'DIRECTION_HORIZONTAL',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
