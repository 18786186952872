import axios from 'axios';
import { AppConfig } from 'mocks/restHandlers';
import config from '../../config.json';

export const getConfig = async () => {
  const { data } = await axios.get(`/config.json`);

  return data;
};

export const getConfigUrl = (endpoint?: keyof AppConfig) => {
  const mode = import.meta.env.MODE;
  if (!window.config) {
    // cypress
    window.config = config;
  }
  return endpoint ? window.config[mode][endpoint] : window.config[mode];
};
